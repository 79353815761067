/* PostCSS: Only imports allowed! */
@import "tailwindcss/base";
@import "./fonts.css";
@import "./input_appearance.css";

@import "tailwindcss/components";
@import "./buttons.css";

@import "tailwindcss/utilities";

@import "tailwindcss/variants";
@import "./icons.css";
@import "./selectize.css";
@import "./slickslider.css";
@import "./navigation.css";
@import "./autocomplete.css";
@import "./modal.css";
@import "./ads.css";


@media all and (min-width:576px) {

    #contactLayer > div {
        height: 100%
    }

    #contactLayer > div > div {
        margin: 0;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
    }

    #rankingLayer > div {
        height: 100%
    }

    #rankingLayer > div > div {
        margin: 0;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
    }

    #announceLayer > div {
        height: 100%
    }

    #announceLayer > div > div {
        margin: 0;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
    }

    #shareLayer > div {
        height: 100%
    }

    #shareLayer > div > div {
        margin: 0;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
    }
}

#shareLayer .btn {
    width: 100%;
    margin: 8px;
}

