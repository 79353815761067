/* MOBILE */
#menu-wrapper {
    height: calc(100% - 64px);
    z-index:2147483641; /*+1 privacy settings button*/
    background-color: rgba(0, 0, 0, 0.2);
}

/* fade in checked menu */
#menu-wrapper .menu-checkbox:checked+.menu {
    overflow-y: auto !important;
    transform: translate3d(0, 0, 0);
}
