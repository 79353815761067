
@font-face {
    font-family: 'Nunito';
    font-display: swap;
    src: url('/fonts/Nunito/Nunito-Italic.woff') format('woff');
    src: url('/fonts/Nunito/Nunito-Italic.ttf') format('truetype');
    font-weight: 400;
    font-style: italic;
}
@font-face {
    font-family: 'Nunito';
    font-display: swap;
    src: url('/fonts/Nunito/Nunito-Bold.woff') format('woff');
    src: url('/fonts/Nunito/Nunito-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}
@font-face {
    font-family: 'Nunito';
    font-display: swap;
    src: url('/fonts/Nunito/Nunito-BoldItalic.woff') format('woff');
    src: url('/fonts/Nunito/Nunito-BoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
}
@font-face {
    font-family: 'Nunito';
    font-display: swap;
    src: url('/fonts/Nunito/Nunito-Regular.woff') format('woff');
    src: url('/fonts/Nunito/Nunito-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}
@font-face {
    font-family: 'wohnungsboerse';
    src:  url('/fonts/wohnungsboerse.eot?8uc04s');
    src:  url('/fonts/wohnungsboerse.eot?8uc04s#iefix') format('embedded-opentype'),
      url('/fonts/wohnungsboerse.ttf?8uc04s') format('truetype'),
      url('/fonts/wohnungsboerse.woff?8uc04s') format('woff'),
      url('/fonts/wohnungsboerse.svg?8uc04s#wohnungsboerse') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
  }
