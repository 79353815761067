@layer components {
    .selectize-control .selectize-input {
        display: flex !important;
        @apply items-center h-12 px-2 text-base rounded-lg shadow-none border-input-border;
    }
    .selectize-control.error .selectize-input {
        @apply border-error;
    }
    .selectize-control .selectize-input input {
        @apply placeholder:text-input-placeholder placeholder:text-base;
        min-width: 100% !important;
    }
    .selectize-control .selectize-input.has-items input {
        min-width: auto !important;
    }
    .selectize-control.plugin-clear_button.single .clear {
        right: calc(8px - 6px + 2.5rem) !important;
    }
    .selectize-control .option.selectize-dropdown-emptyoptionlabel {
        text-align: left !important;
    }
    .selectize-control .selectize-input:after {
        @apply icon-arrow_dropdown font-wohnungsboerse;
        content: var(--tw-content) !important;
        border: none !important;
        top:0px !important;
        height:48px !important;
        width: auto !important;
        line-height:48px;
        font-size:24px;
        position: absolute;
        right:8px;
    }
    .selectize-control .selectize-input.disabled {
        @apply !text-input-border-disabled !bg-body !border-input-border-disabled !opacity-100;
    }
    .selectize-control .selectize-input input:disabled, .selectize-control .selectize-input input:disabled::placeholder, .selectize-control .selectize-input input:disabled::-moz-placeholder {
        @apply !text-input-border-disabled;
    }
    .selectize-control .selectize-input.dropdown-active {
        @apply rounded-t-lg rounded-b-none after:icon-arrow_dropdown_up border-b-fg-light;
    }
    .selectize-control .selectize-input.focus {
        @apply border-input-border-focus;
    }
    .selectize-control .selectize-dropdown {
        @apply overflow-hidden rounded-lg rounded-t-none shadow-sm border-input-border-focus;
    }
    .selectize-control .selectize-dropdown .selectize-dropdown-content .option {
        @apply px-2 py-0 text-base leading-12 hover:bg-bg-canvas;
    }
    .selectize-control .selectize-dropdown .selectize-dropdown-content .option.active {
        @apply bg-bg-canvas;
    }

    .selectize-control.multi {
        height: auto !important;
        min-height: 48px;
    }
    .selectize-control.multi .selectize-input {
        padding-top: 7px !important;
        padding-bottom: 7px !important;
        padding-right:36px !important;
        height: auto !important;
        min-height: 48px;
        flex-wrap: wrap;
    }
    .selectize-control.multi .selectize-input > div {
        padding: 0px 5px !important;
    }
    .selectize-control.multi .selectize-input > div.active {
        background: #efefef !important;
    }

    .selectize-control .remove-single {
        right: 40px !important;
        top: 10px !important;
    }
}