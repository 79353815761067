.slick-dots li button {
    text-indent: -999px;
    @apply w-2 h-2 overflow-hidden rounded-full bg-fg-disabled;
}
.slick-dots li.slick-active button {
    @apply bg-secondary;
}
.lightbox-slider .slick-track {
    @apply !flex items-center h-full;
}
.lightbox-slider .slick-track .slick-slide {
    @apply h-auto;
}
.lightbox-slider .slick-list {
    @apply h-full;
}