@layer components {
/* Do not change this style */
  [class*="before:icon-"]:before, [class*="after:icon-"]:after {
      font-family: 'wohnungsboerse' !important;
      speak: never;
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
      line-height: 1;
    
      /* Better Font Rendering =========== */
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
  }

  .btn[class*="before:icon-"]:before {
    position:relative;
    left:-5px;
    @apply text-2xl;
  }
  .btn[class*="after:icon-"]:after {
    position:relative;
    right:-5px;
    @apply text-2xl;
  }


  /* Paste new icons below this line, remove ":before" and replace "content:" with "--tw-content:" */

  .icon-hide {
    --tw-content: "\e93f";
    color: #8203fc;
  }
  .icon-preview {
    --tw-content: "\e940";
    color: #8203fc;
  }
  .icon-houses {
    --tw-content: "\e93e";
    color: #8203fc;
  }
  .icon-social_xing {
    --tw-content: "\e93c";
    color: #4e4e4e;
  }
  .icon-social_linkedin {
    --tw-content: "\e93d";
    color: #4e4e4e;
  }
  .icon-add_box {
    --tw-content: "\e937";
    color: #4e4e4e;
  }
  .icon-settings {
    --tw-content: "\e938";
    color: #4e4e4e;
  }
  .icon-account_circle {
    --tw-content: "\e939";
    color: #4e4e4e;
  }
  .icon-person_search {
    --tw-content: "\e93a";
    color: #4e4e4e;
  }
  .icon-object-ad {
    --tw-content: "\e93b";
    color: #4e4e4e;
  }
  .icon-document {
    --tw-content: "\e936";
    color: #4e4e4e;
  }
  .icon-pdf {
    --tw-content: "\e935";
  }
  .icon-add {
    --tw-content: "\e928";
  }
  .icon-delete {
    --tw-content: "\e92c";
    color: #8203fc;
  }
  .icon-edit {
    --tw-content: "\e92d";
    color: #8203fc;
  }
  .icon-info {
    --tw-content: "\e92e";
    color: #8203fc;
  }
  .icon-list {
    --tw-content: "\e92f";
    color: #828282;
  }
  .icon-remove {
    --tw-content: "\e930";
  }
  .icon-rotate_left {
    --tw-content: "\e931";
    color: #8203fc;
  }
  .icon-rotate_right {
    --tw-content: "\e932";
    color: #8203fc;
  }
  .icon-show {
    --tw-content: "\e933";
    color: #8203fc;
  }
  .icon-upload {
    --tw-content: "\e934";
    color: #8203fc;
  }
  .icon-star_empty {
    --tw-content: "\e929";
  }
  .icon-star {
    --tw-content: "\e92a";
  }
  .icon-link {
    --tw-content: "\e92b";
  }
  .icon-arrow_dropdown_up {
    --tw-content: "\e927";
  }
  .icon-arrow_dropdown {
    --tw-content: "\e900";
  }
  .icon-arrow_left {
    --tw-content: "\e901";
  }
  .icon-arrow_right {
    --tw-content: "\e902";
  }
  .icon-calendar {
    --tw-content: "\e903";
  }
  .icon-cancel {
    --tw-content: "\e904";
  }
  .icon-check {
    --tw-content: "\e905";
  }
  .icon-check_circle {
    --tw-content: "\e906";
  }
  .icon-chevron_left {
    --tw-content: "\e907";
  }
  .icon-chevron_right {
    --tw-content: "\e908";
  }
  .icon-close {
    --tw-content: "\e909";
  }
  .icon-commercial {
    --tw-content: "\e90a";
    color: #ff5d05;
  }
  .icon-download {
    --tw-content: "\e90b";
  }
  .icon-error {
    --tw-content: "\e90c";
  }
  .icon-euro_symbol {
    --tw-content: "\e90d";
  }
  .icon-favorite_solid {
    --tw-content: "\e90e";
  }
  .icon-favorite_stroke {
    --tw-content: "\e90f";
  }
  .icon-filter {
    --tw-content: "\e910";
  }
  .icon-flag_report {
    --tw-content: "\e911";
  }
  .icon-listing {
    --tw-content: "\e912";
  }
  .icon-location_marker {
    --tw-content: "\e913";
  }
  .icon-login {
    --tw-content: "\e914";
  }
  .icon-logout {
    --tw-content: "\e915";
  }
  .icon-mail {
    --tw-content: "\e916";
  }
  .icon-menu {
    --tw-content: "\e917";
  }
  .icon-menu_more {
    --tw-content: "\e918";
  }
  .icon-message {
    --tw-content: "\e919";
  }
  .icon-phone {
    --tw-content: "\e91a";
  }
  .icon-printer {
    --tw-content: "\e91b";
  }
  .icon-private {
    --tw-content: "\e91c";
    color: #285eea;
  }
  .icon-profile {
    --tw-content: "\e91d";
  }
  .icon-search {
    --tw-content: "\e91e";
  }
  .icon-search_wobo {
    --tw-content: "\e91f";
  }
  .icon-send {
    --tw-content: "\e920";
  }
  .icon-share {
    --tw-content: "\e921";
  }
  .icon-social_fb {
    --tw-content: "\e922";
    color: #fff;
  }
  .icon-social_twitter {
    --tw-content: "\e923";
    color: #fff;
  }
  .icon-sqm {
    --tw-content: "\e924";
  }
  .icon-truck_move {
    --tw-content: "\e925";
  }
  .icon-whatsapp {
    --tw-content: "\e926";
    color: #07e825;
  }
}