/*https://tarekraafat.github.io/autoComplete.js*/

.autoComplete_wrapper {
  position: relative;
}

.autoComplete_wrapper > ul {
  @apply absolute left-0 right-0 z-10 p-0 overflow-y-auto transition-opacity rounded-b-lg bg-bg border-input-border-focus shadow-20 border-1 border-t-fg-light;
  max-height: 300px;
}
.autoComplete_wrapper > ul > div {
  /*heading*/
  padding: 0px;
  text-align:center;
  color: black;
}

.autoComplete_wrapper > ul[hidden],
.autoComplete_wrapper > ul:empty {
  display: block;
  opacity: 0;
  transform: scale(0);
}
.autoComplete_wrapper > ul > li, .autoComplete_wrapper > ul > li a {
  @apply line-clamp-1 text-fg border-b-1 border-fg-light;
}
.autoComplete_wrapper > ul > li.autocomplete-category {
    @apply bg-bg-canvas;
}
.autoComplete_wrapper > ul > li.autocomplete-category a {
    @apply block font-bold;
}

.autoComplete_wrapper > ul > li mark {
  background-color: transparent;
  font-weight:bold;
}

.autoComplete_wrapper > ul > li:hover, .autoComplete_wrapper > ul > li[aria-selected='true'] {
  cursor: pointer;
  @apply bg-bg-canvas;
}